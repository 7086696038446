<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'doctors' })"
    >
      {{ $t('veterinary.doctors') }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="9">
            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">البيانات الشخصية</h5>
              </template>
              <b-row>
                <b-col md="4" class="mb-3">
                  <input-form
                    placeholder="الاسم بالكامل"
                    label="الاسم بالكامل"
                    v-model="doctorData.full_name"
                    id="full_name"
                    name="full name"
                    validate="required"
                    :disabled="$route.name === 'showDoctor'"
                  ></input-form>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="عنوان العيادة"
                    label="عنوان العيادة"
                    v-model="doctorData.clinic_address"
                    name="clinic address"
                    validate="required"
                    :disabled="$route.name === 'showDoctor'"
                  ></input-form>
                </b-col>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="البريد الالكترونى"
                    label="البريد الالكترونى"
                    v-model="doctorData.email"
                    name="email"
                    validate="required"
                    :disabled="$route.name === 'showDoctor'"
                  ></input-form>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="رقم الهاتف"
                    label="رقم الهاتف"
                    v-model="doctorData.primary_phone_number"
                    name="primary phone number"
                    validate="required|numeric"
                    :disabled="$route.name === 'showDoctor'"
                  ></input-form>
                </b-col>
                <b-col lg="6" class="mb-3">
                  <input-form
                    placeholder="رقم الهاتف الثانوى"
                    label="رقم الهاتف الثانوى"
                    v-model="doctorData.secondary_phone_number"
                    name="secondary phone number"
                    validate="numeric"
                    :disabled="$route.name === 'showDoctor'"
                  ></input-form>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div
                      class="setting-box-inner d-flex align-items-center gap_2"
                    >
                      <p class="setting-box-title">نسبة العمولة</p>
                      <div class="d-flex align-items-center gap_1">
                        <input-form
                          class="text-center m-0 matone-value"
                          placeholder=""
                          v-model="doctorData.fees"
                          :disabled="$route.name === 'showDoctor'"
                          name="clinic_examine"
                        />
                        <span class="small-text">%</span>
                      </div>
                    </div>
                </b-col>
              </b-row>

            <textarea-form placeholder="الوصف" v-model="doctorData.description" label="وصف" name="description" :disabled="$route.name === 'showDoctor'"></textarea-form>

            </b-card>

            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">الصور</h5>
              </template>
              <div v-if="$route.name === 'editDoctor'">
                <div v-for="(item, index) in doctorData.uploaded_documents" :key="item.doc.id" class="mb-4">
                  <cropper-images
                    :label="item.doc.title"
                    @cropper-save="(file) => saveMasterImage(file, index)"
                    :progressLoading="loadingLogo"
                    :showProgress="false"
                    :multi="false"
                    :imageUrl="item.url"
                  />
                </div>
              </div>
              <div v-else>
                <div v-for="item in doctorData.uploaded_documents" :key="item.id" class="mb-4">
                  <div>
                    <span>{{ item.doc.title }}</span>
                    <!-- <hr /> -->
                    <div class="d-flex gap-3 flex-wrap justify-content-between mb-4 mt-2" style="gap: 10px;">
                      <img :src="item.url" class="rounded-circle object-fit-cover" style="width: 80px; height: 40px;"/>
                      <b-button variant="outline-primary" id="show-btn" @click="showModal({url: item.url, title: item.doc.title})">عرض الصورة</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
            <b-card
              class="iq-border-radius-10 mb-4"
              v-if="$route.name === 'showDoctor'"
            >
              <template v-slot:header>
                <h5 class="text-primary">الخدمات التى يقوم بتقديمها</h5>
              </template>
              <div class="dashboard-custom-checkbox">
                <b-form-checkbox-group
                  v-model="doctorServiceSelected"
                  :options="doctorServiceOptions"
                  class="dashboard-custom-checkbox-inner d-flex align-items-center flex-wrap"
                  value-field="item"
                  text-field="name"
                  disabled
                ></b-form-checkbox-group>
              </div>
            </b-card>

            <!-- ( doctorData.prices.consulting || doctorData.prices.clinic_examine || doctorData.prices.outdoor_examine ) -->
            <b-card
              class="mb-4"
              v-if="$route.name === 'showDoctor' && ( doctorData.prices.consulting || doctorData.prices.clinic_examine || doctorData.prices.outdoor_examine )"
            >
              <template v-slot:header>
                <h5 class="text-primary">الاسعار</h5>
              </template>
              <b-row>
                <b-col sm="12">
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                    v-if="doctorData.prices.clinic_examine"
                  >
                    <p class="setting-box-title">كشف العيادة</p>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="doctorData.prices.clinic_examine"
                        :disabled="$route.name === 'showDoctor'"
                        name="clinic_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                    v-if="doctorData.prices.outdoor_examine"
                  >
                    <p class="setting-box-title">زيارة منزلية</p>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="doctorData.prices.outdoor_examine"
                        :disabled="$route.name === 'showDoctor'"
                        name="clinic_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                    v-if="doctorData.prices.consulting"
                  >
                    <p class="setting-box-title">استشارة</p>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="doctorData.prices.consulting"
                        :disabled="$route.name === 'showDoctor'"
                        name="clinic_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <b-card v-if="$route.name === 'showDoctor'">
              <template v-slot:header>
                  <div class="d-flex align-items-center flex-wrap gap_2">
                    <h5 class="text-primary">طريقة الكشف</h5>
                    <div class="d-flex align-items-center justify-content-between flex-wrap gap_1">
                      <b-form-radio-group
                        id="etectionMethod"
                        v-model="doctorData.examine_method"
                        :options="optionsDetectionMethod"
                        aria-describedby="ariaDescribedby"
                        name="detectionMethod"
                        disabled
                        class="detection-custom-radio d-flex align-items-center"
                      ></b-form-radio-group>
                      <div class="d-flex align-items-center gap_2 setting-box-inp-detection-time" v-if="doctorData.examine_method === 'appointment'">
                        <h6 class="setting-box-title setting-box-title-detection-time font-size-16">مدة الكشف</h6>
                        <div class="d-flex align-items-center gap_2">
                          <div class="d-flex align-items-center gap_1">
                            <input-form
                              class="text-center m-0 matone-value"
                              placeholder="1"
                              disabled
                              v-model="duration.hours"
                              name="hours" />
                            <span class="small-text">ساعة</span>
                          </div>
                          <div class="d-flex align-items-center gap_1">
                            <input-form
                              class="text-center m-0 matone-value"
                              placeholder="30"
                              disabled
                              v-model="duration.minutes"
                              name="minutes" />
                            <span class="small-text">دقيقة</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </template>
              <div class="">
                <h5 class="text-primary">مواعيد العمل</h5>
                <div class="p-4">
                  <div
                    class="d-flex align-items-center gap_1 time-of-work-item flex-wrap"
                    v-for="(timeOfWork, index) in doctorData.work_times"
                    :key="index"
                  >
                    <div
                      class="time-of-work-item-controls d-flex align-items-center gap_1"
                    >
                      <span
                        class="time-of-work-item-controls-label font-size-20"
                        >{{ timeOfWork.day }}</span
                      >

                      <b-form-checkbox
                        :id="`time-of-work-item-controls-checkbox-${index}`"
                        v-model="timeOfWork.is_active"
                        :name="`time-of-work-item-controls-checkbox-${index}`"
                        class="d-flex align-items-center dashboard-custom-checkbox time-of-work-item-controls-checkbox"
                        :value="false"
                        :unchecked-value="true"
                        disabled
                        ariaDescribedby="item-checkbox-day"
                      >
                        مغلق
                      </b-form-checkbox>
                    </div>

                      <div
                        v-if="timeOfWork.is_active"
                        class="d-flex flex-column gap_1"
                      >
                        <div
                          class="d-flex align-items-center gap_4 flex-wrap"
                          v-for="(timeItem, idx) in timeOfWork.working_periods"
                          :key="idx"
                        >
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              من
                            </h6>
                            <flat-pickr
                              v-model="timeItem.from"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date"
                              disabled
                            />
                          </div>
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              الى
                            </h6>
                            <flat-pickr
                              v-model="timeItem.to"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date-to"
                              disabled
                            />
                          </div>
                        </div>
                        <div v-if="timeOfWork.max_appointments">
                              <h6 class="setting-box-title">
                                الحد الأقصى للحجوزات
                              </h6>
                              <input-form
                                class="text-center m-0 matone-value w-50"
                                :placeholder="'عدد الحجوزات'"
                                v-model="timeOfWork.max_appointments"
                                disabled
                                name="max_examinations_Count"
                              />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="$route.name !== 'showDoctor'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5"
          >
            <b-button
              variant="primary"
              class="px-5 py-3"
              type="submit"
              v-if="!loadingSubmit"
              >تعديل</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <b-modal ref="modal-photo" id="modal-photo" centered hide-footer>
      <template #modal-title>
        {{ selectedPhoto.title }}
      </template>
      <div class="image-container d-block text-center">
        <img :src="selectedPhoto.url" class="img-fluid" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../../services/index'
import commonMixin from '@/mixins/commonMixin'

export default {
  components: {},
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      selectedPhoto: {},
      loadingLogo: 0,
      loading: false,
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: false,
        defaultDate: '14:30'
      },
      loadingSubmit: false,
      doctorServiceOptions: [
        { item: { allow_clinic_examine: true }, name: 'كشف عيادة' },
        { item: { allow_outdoor_examine: true }, name: 'زيارة منزلية' }
      ],
      optionsDetectionMethod: [
        { text: 'بأولوية الحضور', value: 'first-come-first-served' },
        { text: 'ميعاد محدد', value: 'appointment' }
      ],
      doctorServiceSelected: [{ allow_clinic_examine: true }],
      doctorData: {
        full_name: '',
        clinic_address: '',
        email: '',
        primary_phone_number: '',
        secondary_phone_number: '',
        personal_picture: '',
        prices: {
          consulting: '',
          outdoor_examine: '',
          clinic_examine: ''
        }
      }
    }
  },
  computed: {
    duration () {
      const [hours, minutes] = this.doctorData.examination_duration.split(':')
      return {
        hours: parseInt(hours, 10),
        minutes: parseInt(minutes, 10)
      }
    }
  },
  methods: {
    getdoctorData () {
      api.getDoctor(this.$route.params.id).then((res) => {
        this.doctorData = res.data
        this.doctorServiceSelected = [
          { allow_clinic_examine: res.data.allow_clinic_examine === 1 },
          { allow_outdoor_examine: res.data.allow_outdoor_examine === 1 },
          { allow_consulting: res.data.allow_outdoor_examine === 1 }
        ]
      })
    },
    showModal (item) {
      console.log(item)
      this.$refs['modal-photo'].show()
      this.selectedPhoto = { url: item.url, title: item.title }
    },
    onSubmit () {
      if (this.$route.name === 'editDoctor') {
        this.editDoctor()
      } else {
        console.log('test')
      }
    },
    saveMasterImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options)
        .then((res) => {
          this.doctorData.personal_picture = res.data.url
          this.showSuccessUploadFile()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editDoctor () {
      this.loadingSubmit = true
      api
        .editDoctor(this.$route.params.id, {
          full_name: this.doctorData.full_name,
          clinic_address: this.doctorData.clinic_address,
          email: this.doctorData.email,
          primary_phone_number: this.doctorData.primary_phone_number,
          secondary_phone_number: this.doctorData.secondary_phone_number,
          personal_picture: this.doctorData.personal_picture,
          uploaded_documents: this.doctorData.uploaded_documents,
          description: this.doctorData.description,
          fees: this.doctorData.fees
        })
        .then(() => {
          this.loadingSubmit = false
          core.showSnackbar('success', 'تم تعديل بنجاح')
          this.$router.push({ name: 'doctors' })
        })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getdoctorData()
    }
  }
}
</script>
<style scoped>
.setting-box-title {
  min-width: 140px;
  color: #3f414d !important;
  font-weight: normal !important
}
.setting-box-inner {
  margin-bottom: 30px;
}
</style>
